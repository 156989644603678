import Router, { Route } from "preact-router"
import { ChakraProvider } from "@chakra-ui/react"
import { Fonts } from "../../styles/Fonts"
import { theme } from "../../styles/theme"

import { Home } from "../../pages/Home"
import { Contact } from "../../pages/Contact"
import { Credits } from "../../pages/Credits"
import { Played } from "../../pages/Played"
import { Show } from "../../pages/Show"
import { PrivacyPolicy } from "../../pages/PrivacyPolicy"
import { DataProvider, useData } from "../Data"

const Nothing = () => null

export const App = () => (
  <DataProvider>
    <ChakraProvider theme={theme}>
      <Fonts />
      <Home />
      <Router>
        <Route path="/" component={Nothing} />
        <Route path="/contact" component={Contact} />
        <Route path="/credits" component={Credits} />
        <Route path="/played" component={Played} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/:show*" component={Show} />
      </Router>
    </ChakraProvider>
  </DataProvider>
)
