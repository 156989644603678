import { h } from "preact"
import { useFetch } from "../../hooks/useFetch"
import { Box, Stack, Image } from "@chakra-ui/react"
import { CalendarData } from "../../api/totallyradio"
import { ContentData } from "../../api/google-sheet"

import logoSrc from "../../assets/logo.png"
import { CurrentShow } from "../CurrentShow"
import { Player } from "../Player"
import { HTML } from "../HTML"
import { memo } from "preact/compat"

import smoothscroll from "smoothscroll-polyfill"
if (typeof window !== "undefined") smoothscroll.polyfill()

interface MainProps {
  homeData?: ContentData
  calendarData?: CalendarData
}

export const Main = memo(({ homeData, calendarData }: MainProps) => (
  <Box height="100%" overflowY="auto" padding="0.5rem" display="flex">
    <Box flexGrow={1} flexShrink={1} width="100%">
      <Stack
        minHeight="100%"
        display="flex"
        flexDirection="column"
        spacing="3rem"
      >
        <Stack flexGrow={1} spacing="1rem">
          {calendarData && (
            <>
              <CurrentShow calendarData={calendarData} />
              <Player />
              {homeData && <HTML>{homeData["About"]}</HTML>}
            </>
          )}
        </Stack>
        {calendarData && homeData && (
          <HTML textStyle="small">{homeData["Footer"]}</HTML>
        )}
      </Stack>
      <Image
        position="absolute"
        top="0.25rem"
        right="0.25rem"
        width="6rem"
        display={["block", "none"]}
        src={logoSrc}
      />
    </Box>
    <Box
      display={["none", "block"]}
      width={["25%"]}
      paddingLeft="0.5rem"
      flexShrink={0}
    >
      <Image src={logoSrc} width="100%" position="sticky" top="0" />
    </Box>
  </Box>
))
