import { Box, Stack, Text } from "@chakra-ui/react"
import { HTML } from "../HTML"
import { getShowID } from "../../api/totallyradio"
import { CalendarData } from "../../api/totallyradio"
import { formatTime, getDate } from "../../utils/date"

interface CurrentShowProps {
  calendarData: CalendarData
}
export const CurrentShow = ({ calendarData }: CurrentShowProps) => {
  const calendarDays = Object.keys(calendarData.days)
  const today = calendarDays.find(day => calendarData.days[day].today)
  if (!today) {
    console.warn("No day with today: true in calendar")
    return null
  }
  const currentShow = Object.values(calendarData.shows[today].data).find(
    show => show.current_show
  )
  if (!currentShow) {
    console.warn("No show with current_show: true in calendar")
    return null
  }
  const id = getShowID(currentShow.url)
  const maxWordLength = currentShow.name
    .split(/\s/)
    .reduce((max, word) => Math.max(max, word.length), 0)
  return (
    <Stack as="a">
      <Box
        as="a"
        href={id ? "/" + id : undefined}
        cursor={id ? "pointer" : undefined}
      >
        <Text
          as="h2"
          textStyle="huge"
          fontSize={maxWordLength > 10 ? ["40px", "80px", "100px"] : undefined}
        >
          {formatTime(getDate(currentShow.start_timestamp))}
          <br />
          –&nbsp;{formatTime(getDate(currentShow.end_timestamp))}
          <br />
          <HTML sx={{ hyphens: "auto" }}>{currentShow.name}</HTML>
        </Text>
      </Box>
      <HTML>{currentShow.description}</HTML>
    </Stack>
  )
}
