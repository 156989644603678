import { useState } from "preact/hooks"
import { Image } from "@chakra-ui/image"
import { ChakraProps } from "@chakra-ui/system"

import car from "../../assets/doodles/car.png"
import coffee from "../../assets/doodles/coffee.png"
import dog from "../../assets/doodles/dog.png"
import tea from "../../assets/doodles/tea.png"
import noodles from "../../assets/doodles/noodles.png"

const doodles = [
  dog as string,
  car as string,
  coffee as string,
  tea as string,
  noodles as string,
]

export const Doodle = (props: ChakraProps) => {
  const [show, setShow] = useState(false)
  const [index] = useState(() => Math.floor(Math.random() * doodles.length))
  return <Image src={doodles[index]} {...props} />
}
