import { Box, Stack, Input, chakra } from "@chakra-ui/react"
import { Text } from "@chakra-ui/layout"
import { Page } from "../../components/Page"
import AutosizeTextarea from "react-autosize-textarea"
import { SHEET_URL, ContactResponse } from "../../api/google-sheet"
import { FormEvent } from "react"
import { useState } from "preact/hooks"

const Textarea = chakra(AutosizeTextarea)

const inputStyles = {
  borderBottom: "1px solid black",
  display: "block",
  width: "100%",
  outline: 0,
}

export const Contact = () => {
  const [state, setState] = useState<{
    sending: boolean
    error: string | null
    success: boolean
  }>({
    sending: false,
    error: null,
    success: false,
  })
  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    const formData = new FormData(form)
    const url = new URL(SHEET_URL)
    url.searchParams.append("Name", formData.get("Name") as string)
    url.searchParams.append("Email", formData.get("Email") as string)
    url.searchParams.append("Message", formData.get("Message") as string)
    setState(state => ({ ...state, sending: true }))
    fetch(url.href, { method: "POST" })
      .then(r => r.json())
      .then((res: ContactResponse) => {
        setState(state => ({
          ...state,
          sending: false,
          error: res.error,
          success: res.error === null,
        }))
      })
  }
  const disabled = state.sending || state.success
  return (
    <Page>
      <Stack as="form" spacing="1rem" onSubmit={onSubmit}>
        <Text textStyle="title">Get in touch</Text>
        {state.error && <Text>Error: {state.error}</Text>}
        <Stack spacing="0.5rem">
          <Text>Name</Text>
          <Box
            as="input"
            type="text"
            name="Name"
            disabled={disabled}
            required
            {...inputStyles}
          />
        </Stack>
        <Stack spacing="0.5rem">
          <Text>Email</Text>
          <Box
            as="input"
            type="email"
            name="Email"
            disabled={disabled}
            required
            {...inputStyles}
          />
        </Stack>
        <Stack spacing="0.5rem">
          <Text>Message</Text>
          <Textarea
            required
            name="Message"
            disabled={disabled}
            {...inputStyles}
          />
        </Stack>
        <Box>
          <Box
            as="input"
            type="submit"
            value={
              state.sending ? "Sending..." : state.success ? "Thanks!" : "Send"
            }
            width="auto"
            backgroundColor="transparent"
            textDecoration="underline"
            cursor={disabled ? undefined : "pointer"}
            disabled={disabled}
          />
        </Box>
      </Stack>
    </Page>
  )
}
