import { keyframes, Theme } from "@chakra-ui/react"

const random = (from: number, to: number) => from + Math.random() * (to - from)
const randomTransform = () =>
  `translate(${random(-2, 2)}px, ${random(-2, 2)}px)`
export const wobbleAnimation = keyframes`
  0% { transform: ${randomTransform()} }
  10% { transform: ${randomTransform()} }
  20% { transform: ${randomTransform()} }
  30% { transform: ${randomTransform()} }
  40% { transform: ${randomTransform()} }
  50% { transform: ${randomTransform()} }
  60% { transform: ${randomTransform()} }
  70% { transform: ${randomTransform()} }
  80% { transform: ${randomTransform()} }
  90% { transform: ${randomTransform()} }
`

export const theme = {
  config: {
    useSystemColorMode: false,
  },
  styles: {
    global: (props: any) => ({
      "*": {
        position: "relative",
        wordWrap: "break-word",
      },
      html: {
        fontSize: ["14px", "18px"],
        lineHeight: props.theme.lineHeights.default,
        fontFamily: props.theme.fonts.default,
        textUnderlineOffset: "0.085em",
        backgroundColor: props.theme.colors.background,
        overscrollBehavior: "none",
        fontDisplay: "block",
        "–webkitOverflowScrolling": "touch",
      },
      "html, body": { height: "100%" },
      "input, textarea": {
        borderRadius: 0,
      },
    }),
  },
  breakpoints: ["0px", "768px", "1024px", "1200px", "1600px"],
  lineHeights: {
    default: 1.2,
    heading: 0.9,
  },
  fonts: {
    default: "Courier Prime, monospace",
    heading: "Liberation Sans Narrow, sans-serif",
  },
  colors: {
    background: "var(--background-color, #deb981)",
  },
  textStyles: {
    huge: {
      fontSize: ["60px", "100px", "120px"],
      lineHeight: "heading",
      fontFamily: "heading",
    },
    title: {
      fontSize: ["60px", null, "80px"],
      lineHeight: "heading",
      fontFamily: "heading",
    },
    heading: {
      fontSize: "30px",
      lineHeight: "heading",
      fontFamily: "heading",
    },
    headingSmall: {
      fontFamily: "heading",
    },
    small: {
      fontSize: ["12px", "13px"],
      lineHeight: [1.4, null, 1.2],
    },
  },
  layerStyles: {
    fill: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  },
}
