import { Box, ChakraProps } from "@chakra-ui/react"
import { wobbleAnimation } from "../../styles/theme"

type HTMLProps = { children: string; animateLinks?: boolean } & ChakraProps
export const HTML = ({
  children,
  animateLinks = true,
  ...props
}: HTMLProps) => {
  return (
    <Box
      sx={{
        a: {
          display: animateLinks ? "inline-block" : undefined,
          textDecoration: "underline",
          "&:hover": {
            animation: `${wobbleAnimation} 2s infinite linear`,
          },
        },
        "p:not(:last-child)": {
          marginBottom: "1rem",
        },
      }}
      dangerouslySetInnerHTML={{
        __html: children.trim().replace(/\n/g, "<br/>"),
      }}
      {...props}
    />
  )
}
