import { createContext, JSX } from "preact"
import { ContentData, SHEET_URL } from "../../api/google-sheet"
import { FetchResult, useFetch } from "../../hooks/useFetch"
import { useContext, useEffect, useMemo } from "preact/hooks"
import { CalendarData, CALENDAR_URL } from "../../api/totallyradio"

const DataContext =
  createContext<{
    content: FetchResult<ContentData>
    calendar: FetchResult<CalendarData>
  } | null>(null)
export const useData = () => {
  const data = useContext(DataContext)
  if (data === null) throw new Error("useData called outside of DataContext")
  return data
}

interface SiteProps {
  children: JSX.Element | JSX.Element[]
}
export const DataProvider = ({ children }: SiteProps) => {
  const content = useFetch<ContentData>(SHEET_URL)
  const calendar = useFetch<CalendarData>(CALENDAR_URL, 10000)
  const data = useMemo(() => ({ content, calendar }), [content, calendar])
  useEffect(() => {
    if (!content.result || !content.result["Background Color"]) return
    document.documentElement.style.setProperty(
      "--background-color",
      content.result["Background Color"]
    )
  }, [content.result])
  if (!content.result) return null
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>
}
