import { h, JSX } from "preact"
import { Box, useBreakpointValue } from "@chakra-ui/react"
import { Main } from "../../components/Main"
import { ContentData, SHEET_URL } from "../../api/google-sheet"
import { CalendarData, CALENDAR_URL } from "../../api/totallyradio"
import { useFetch } from "../../hooks/useFetch"
import { Calendar } from "../../components/Calendar"
import { useLayoutEffect, useRef, useState } from "preact/hooks"
import { useData } from "../../components/Data/Data"

const scrollIntoView = ({ currentTarget }: { currentTarget: Element }) =>
  currentTarget.scrollIntoView({ behavior: "smooth" })

export const Home = () => {
  const { content, calendar } = useData()
  const containerRef = useRef<HTMLDivElement>()
  const [isScrolled, setIsScrolled] = useState(false)
  const canOpenShows = useBreakpointValue([isScrolled, true])
  useLayoutEffect(() => {
    const container = containerRef.current
    if (!container) throw new Error("ref not set")
    const onScroll = () => setIsScrolled(container.scrollLeft > 0)
    container.addEventListener("scroll", onScroll)
    return () => container.removeEventListener("scroll", onScroll)
  })
  return (
    <Box
      ref={containerRef}
      position="absolute"
      left={0}
      top={0}
      width="100%"
      height="100%"
      overflowX="scroll"
      display="flex"
      alignItems="stretch"
    >
      <Box
        width={[10 / 12, 6 / 8, 1 / 2]}
        flexShrink={0}
        onClick={scrollIntoView}
      >
        <Main homeData={content.result} calendarData={calendar.result} />
      </Box>
      <Box
        width={[(14 / 5) * 100 + "%", (14 / 8) * 100 + "%", 7 / 8]}
        flexShrink={0}
        onClick={(event: React.MouseEvent) => {
          if (containerRef.current.scrollLeft <= 0) scrollIntoView(event)
        }}
      >
        <Box height="100%" pointerEvents={canOpenShows ? undefined : "none"}>
          {calendar.result && <Calendar calendarData={calendar.result} />}
        </Box>
      </Box>
    </Box>
  )
}
