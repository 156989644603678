import { JSX } from "preact"
import { MouseEvent } from "react"
import { route } from "preact-router"
import { Box } from "@chakra-ui/react"

interface PageProps {
  children: JSX.Element
}
export const Page = ({ children }: PageProps) => (
  <Box
    position="absolute"
    top="0"
    left="0"
    width="100%"
    height="100%"
    zIndex="20"
    overflowY="scroll"
    onClick={(e: MouseEvent) => {
      if (e.target === e.currentTarget) route("/")
    }}
  >
    <Box
      background="white"
      ml={["1rem", "auto"]}
      mr={["0", "auto"]}
      my={["10vh", "15vh"]}
      minHeight="70vh"
      maxWidth="40em"
      padding="1rem"
    >
      {children}
    </Box>
  </Box>
)
