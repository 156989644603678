import { useEffect, useState } from "preact/hooks"

export interface FetchResult<TResult> {
  result: TResult | undefined
  loading: boolean
  error: Error | undefined
}

export const useFetch = <TResult>(
  input: Request | string,
  interval?: number,
  key: (result: TResult) => string = JSON.stringify
) => {
  const [state, setState] = useState<FetchResult<TResult>>({
    result: undefined,
    loading: true,
    error: undefined,
  })
  useEffect(() => {
    let cancelled = false
    let timer: ReturnType<typeof setTimeout> | null = null
    const get = () => {
      setState(state => ({ ...state, loading: true }))
      fetch(input)
        .then(response => {
          if (!response.ok) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .then((newResult: TResult) => {
          if (cancelled) return
          setState(prevState => {
            if (
              prevState.result !== undefined &&
              key &&
              key(prevState.result) === key(newResult)
            ) {
              return { ...prevState, loading: false }
            } else {
              return { ...prevState, result: newResult, loading: false }
            }
          })
          if (interval !== undefined) {
            timer = setTimeout(get, interval)
          }
        })
        .catch((error: Error) => {
          if (cancelled) return
          setState({ result: undefined, loading: false, error })
        })
    }
    get()
    return () => {
      cancelled = true
      if (timer !== null) clearTimeout(timer)
    }
  }, [input, interval])
  return state
}
