import { Box, Stack, Text } from "@chakra-ui/layout"
import { HTML } from "../HTML"
import { CalendarShowData, getShowID } from "../../api/totallyradio"
import { formatTime } from "../../utils/date"
import { MIN_DURATION } from "."
import { theme, wobbleAnimation } from "../../styles/theme"
import { useLayoutEffect, useRef, useState } from "preact/hooks"
import { Doodle } from "./Doodle"

const ScrollIntoView = () => {
  const ref = useRef<HTMLDivElement>()
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])
  return (
    <Box
      ref={ref}
      visibility="hidden"
      position="absolute"
      top={theme.lineHeights.default * -5 + "rem"}
    />
  )
}

const formatDurationShort = (start: Date, end: Date) => (
  <>
    {formatTime(start)}&nbsp;–&nbsp;{formatTime(end)}
  </>
)
const formatDurationLong = (start: Date, end: Date) => (
  <>
    {formatTime(start)}
    <br />
    –&nbsp;{formatTime(end)}
  </>
)

export interface CalendarShowProps {
  start: Date
  end: Date
  showData: CalendarShowData
}
export const CalendarShow = ({ start, end, showData }: CalendarShowProps) => {
  const duration = end.getTime() - start.getTime()
  const isShort = duration <= MIN_DURATION
  const id = getShowID(showData.url)
  const [showDoodle] = useState(
    () => duration > 1000 * 60 * 60 && Math.random() < 0.25
  )
  return (
    <>
      {showData.current_show && <ScrollIntoView />}
      <Stack
        as="a"
        role="group"
        padding="0.5rem"
        height="100%"
        href={id ? "/" + id : undefined}
        display="flex"
        flexDirection="column"
      >
        <Box flexGrow={1} height="100%">
          <Stack
            position="sticky"
            maxHeight="100%"
            top={theme.lineHeights.default * 5.5 + "rem"}
            display="flex"
            flexDirection="column"
            _groupHover={
              id
                ? { animation: `${wobbleAnimation} 2s infinite linear` }
                : undefined
            }
          >
            <Text textStyle="headingSmall">
              {isShort
                ? formatDurationShort(start, end)
                : formatDurationLong(start, end)}
            </Text>
            <HTML textStyle="headingSmall">{showData.name}</HTML>
            {!isShort && (
              <HTML
                textStyle="small"
                overflow="hidden"
                minHeight={0}
                flexGrow={1}
              >
                {showData.description}
              </HTML>
            )}
          </Stack>
        </Box>
        {showDoodle && (
          <Doodle
            display="block"
            flexShrink={1}
            minHeight={0}
            objectFit="contain"
            objectPosition="bottom left"
          />
        )}
      </Stack>
    </>
  )
}
