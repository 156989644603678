import { useEffect, useRef, useState } from "preact/hooks"
import { Stack, Text } from "@chakra-ui/react"
import {
  TrackData,
  NOW_PLAYING_URL,
  LIVE_STREAM_URL,
} from "../../api/totallyradio"
import { Box, Image } from "@chakra-ui/react"
import { Link } from "../Link"
import { useFetch } from "../../hooks/useFetch"

import playSVG from "../../assets/play.svg"
import pauseSVG from "../../assets/pause.svg"
import { theme, wobbleAnimation } from "../../styles/theme"

type AudioState = "paused" | "loading" | "playing"

const AudioPlayer = () => {
  const audioRef = useRef<HTMLAudioElement>()
  const [state, setState] = useState<AudioState>("paused")
  return (
    <Box paddingBottom="100%">
      <audio
        ref={audioRef}
        src={LIVE_STREAM_URL}
        style={{ display: "none" }}
        onPlaying={() => setState("playing")}
        onPause={() => setState("paused")}
        onWaiting={() => setState("loading")}
      />
      <Box
        role="group"
        layerStyle="fill"
        border="1px solid black"
        borderRadius="50%"
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          const audio = audioRef.current
          if (!audio) throw new Error("ref not set")
          if (state === "paused") {
            audio.play()
          } else {
            audio.pause()
          }
        }}
      >
        {state === "loading" ? (
          "..."
        ) : (
          <Image
            width="60%"
            height="60%"
            objectFit="contain"
            src={state === "playing" ? pauseSVG : playSVG}
            animation={
              state === "paused"
                ? `${wobbleAnimation} 2s infinite linear`
                : undefined
            }
            _groupHover={{ animation: `${wobbleAnimation} 2s infinite linear` }}
          />
        )}
      </Box>
    </Box>
  )
}

const NowPlaying = () => {
  const { result, loading, error } = useFetch<TrackData>(NOW_PLAYING_URL, 5000)
  if (!result) return null
  return (
    <>
      Now playing:
      <br />
      <Text as="span" display="inline-block">
        {result.artist}
      </Text>{" "}
      ——{" "}
      <Text as="span" display="inline-block">
        {result.title}
      </Text>
      <br />
      <Link
        href="/played"
        textDecoration="underline"
        display="inline-block"
        _hover={{ animation: `${wobbleAnimation} 2s infinite linear` }}
      >
        Previously...
      </Link>
    </>
  )
}

export const Player = () => (
  <Box display="flex">
    <Box
      width={theme.lineHeights.default * 3 + "rem"}
      marginRight="1rem"
      flexShrink={0}
    >
      <AudioPlayer />
    </Box>
    <Box flexGrow={1}>
      <NowPlaying />
    </Box>
  </Box>
)
