export const SHEET_URL =
  "https://script.google.com/macros/s/AKfycbyXsviOzOcoXQz3klaVFUkK60ayn93EwBLbnowxvNtlXcKEl89ROX1nHTDJLIYr2UFR/exec"

export interface ContactData {
  Name: string
  Email: string
  Message: string
}

export interface ContactResponse {
  error: null | string
}

export interface ContentData {
  "Background Color": string
  About: string
  Footer: string
  "Privacy Policy": string
  Credits: string
}
