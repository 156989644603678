export interface ApiError {
  code: number
  message: string
}

export interface CalendarShowData {
  start_timestamp: string
  end_timestamp: string
  name: string
  day_name: string
  description: string
  entry_id: string
  instance_id: string
  instance_description: string
  record: string
  url: string
  image_path: string
  starts: string
  ends: string
  time: string
  current_show: boolean
}

export interface DayData {
  id: string
  name: string
  date: string
  today: boolean
}

export interface CalendarData {
  shows: Record<
    string,
    {
      data: CalendarShowData[] | { [id: string]: CalendarShowData }
      today: boolean
    }
  >
  days: Record<string, DayData>
}

export const CALENDAR_URL =
  "https://metadata.slackcity.org.uk/api/get_calendar/slackcity"

export interface TrackData {
  brand_id: string
  status: string
  added: string
  remaining: string
  title: string
  artist: string
  album: string
  label: string
  seconds: string
  duration: string
  release_date: string
  spotify_track_id: string | false
  spotify_image_url: string | false
  deezer_track_id: string | false
  deezer_image_url: string | false
  youtube_id: string | false
  youtube_image_url: string | false
  image_url: string
  spotify_track_url: string | false
  deezer_track_url: string | false
  youtube_url: string | false
  refresh: number
  recently_played_url: string
  text: string
  html: string
}

export const NOW_PLAYING_URL =
  "https://metadata.slackcity.org.uk/api/nowplaying/slackcity"

export interface PlayedData {
  tracks: (TrackData & { started: string; released: string })[]
}

export const PLAYED_URL =
  "https://metadata.slackcity.org.uk/api/played/slackcity"

export interface ShowData {
  title: string
  image: string
  summary: string
  categories: { term: string; scheme: string }[]
}

export const SHOW_URL = (id: string) =>
  `https://www.totallyradio.com/whiterose/stations/totallyradio/shows/${id}`

export interface EpisodeData {
  title: string
  show: string
  id: string
}

export interface EpisodesData {
  data: EpisodeData[]
}

export const EPISODES_URL = (id: string) =>
  `https://www.totallyradio.com/whiterose/stations/totallyradio/shows/${id}/episodes`

export const EPISODE_WEB_URL = (data: EpisodeData) =>
  `https://www.totallyradio.com/shows/${data.show}/episodes/${data.id}`

export const SHOW_WEB_URL = (id: string) =>
  `https://www.totallyradio.com/shows/${id}`

export const LIVE_STREAM_URL =
  "https://listen-totallyradio.sharp-stream.com/90_slack_city_192"

export const getShowID = (url: string) => {
  const parts = url.split("/")
  return parts[parts.length - 1]
}
