import { Stack, Image } from "@chakra-ui/react"
import { Text } from "@chakra-ui/layout"
import { HTML } from "../../components/HTML"
import { Page } from "../../components/Page"
import { useFetch } from "../../hooks/useFetch"
import {
  EpisodesData,
  EPISODES_URL,
  EPISODE_WEB_URL,
  ShowData,
  SHOW_URL,
  SHOW_WEB_URL,
  ApiError,
} from "../../api/totallyradio"
import { Link } from "../../components/Link"

interface ShowProps {
  show: string
}
export const Show = ({ show }: ShowProps) => {
  const { result: showData } = useFetch<ShowData | ApiError>(SHOW_URL(show))
  const { result: episodesData } = useFetch<EpisodesData>(EPISODES_URL(show))
  if (showData && "code" in showData) {
    return (
      <Page>
        <Stack spacing="2rem">
          <Text textStyle="title">Error {showData.code}</Text>
          <Text>{showData.message}</Text>
          <Link href="/" textDecoration="underline">
            Go home
          </Link>
        </Stack>
      </Page>
    )
  } else {
    return (
      <Page>
        <Stack spacing="2rem">
          {showData && (
            <>
              <Text textStyle="title">{showData.title}</Text>
              <Image src={showData.image} />
              <HTML>{showData.summary}</HTML>
            </>
          )}
          {episodesData && episodesData.data.length && (
            <Stack>
              <Text textStyle="heading">Listen on demand</Text>
              {episodesData.data.slice(0, 3).map(episodeData => (
                <Text
                  as="a"
                  display="block"
                  target="_blank"
                  href={EPISODE_WEB_URL(episodeData)}
                  textDecoration="underline"
                >
                  {episodeData.title}
                </Text>
              ))}
              <Text
                as="a"
                display="block"
                target="_blank"
                href={SHOW_WEB_URL(show)}
                textDecoration="underline"
              >
                More...
              </Text>
            </Stack>
          )}
        </Stack>
      </Page>
    )
  }
}
