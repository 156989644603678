import { Box, Stack, Image } from "@chakra-ui/react"
import { Text } from "@chakra-ui/layout"
import { Page } from "../../components/Page"
import { useFetch } from "../../hooks/useFetch"
import { PlayedData, PLAYED_URL } from "../../api/totallyradio"

export const Played = () => {
  const { result, loading, error } = useFetch<PlayedData>(PLAYED_URL, 10000)
  return (
    <Page>
      <Stack spacing="2rem">
        <Text textStyle="title">Recently played...</Text>
        {result &&
          result.tracks.map(track => (
            <Box display="flex">
              <Image
                src={track.image_url}
                width="8rem"
                height="8rem"
                marginRight="1rem"
                flexShrink={0}
              />
              <Box>
                {track.started}
                <br />
                <br />
                {track.artist}
                <br />
                {track.title}
                <br />
                <br />
                From the album "{track.album}", released in {track.released} on{" "}
                {track.label}
                <br />
                <br />
                {track.spotify_track_url && (
                  <Text
                    as="a"
                    textDecoration="underline"
                    href={track.spotify_track_url}
                    target="_blank"
                    marginRight="1rem"
                  >
                    Spotify
                  </Text>
                )}
                {track.youtube_url && (
                  <Text
                    as="a"
                    textDecoration="underline"
                    href={track.youtube_url}
                    target="_blank"
                    marginRight="1rem"
                  >
                    Youtube
                  </Text>
                )}
                {track.deezer_track_url && (
                  <Text
                    as="a"
                    textDecoration="underline"
                    href={track.deezer_track_url}
                    target="_blank"
                    marginRight="1rem"
                  >
                    Deezer
                  </Text>
                )}
              </Box>
            </Box>
          ))}
      </Stack>
    </Page>
  )
}
