import { Stack } from "@chakra-ui/react"
import { Text } from "@chakra-ui/layout"
import { HTML } from "../../components/HTML"
import { Page } from "../../components/Page"
import { useData } from "../../components/Data/Data"

export const PrivacyPolicy = () => {
  const { content } = useData()
  return (
    <Page>
      <Stack spacing="2rem">
        <Text textStyle="title">Privacy Policy</Text>
        {content.result && <HTML>{content.result["Privacy Policy"]}</HTML>}
      </Stack>
    </Page>
  )
}
