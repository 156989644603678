import { Image, Stack } from "@chakra-ui/react"
import { Text } from "@chakra-ui/layout"
import { HTML } from "../../components/HTML"
import { Page } from "../../components/Page"
import { useData } from "../../components/Data/Data"

export const Credits = () => {
  const { content } = useData()
  return (
    <Page>
      <Stack spacing="2rem">
        <Text textStyle="title">Credits</Text>
        <Image src={"/cool-dog--transparent.png"} />
        {content.result && <HTML>{content.result["Credits"]}</HTML>}
      </Stack>
    </Page>
  )
}
