import { Global } from "@emotion/react"
import LiberationSansNarrow from "../assets/fonts/LiberationSansNarrow-Regular.ttf"

export const Fonts = () => (
  <Global
    styles={`
        @font-face {
          font-family: 'Liberation Sans Narrow';
          src: url(${LiberationSansNarrow});
        }
      `}
  />
)
