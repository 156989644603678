import { CalendarData } from "../../api/totallyradio"
import { Box } from "@chakra-ui/react"
import { CalendarShow, CalendarShowProps } from "./CalendarShow"
import { theme } from "../../styles/theme"
import { format, isEqual, startOfDay } from "date-fns"
import { memo } from "preact/compat"
import { getDate } from "../../utils/date"

export const MIN_DURATION = 15 * 60 * 1000 // shortest show considered for layout (15 min)
export const MIN_LINES = 3.5 // height of shortest show in the calendar
export const LINES_PER_MS = MIN_LINES / MIN_DURATION

const DAY_LENGTH = 24 * 60 * 60 * 1000
const DAY_HEIGHT = DAY_LENGTH * LINES_PER_MS

interface CalendarHeaderProps {
  days: Date[]
}
const CalendarHeader = ({ days }: CalendarHeaderProps) => (
  <Box
    position="sticky"
    top={0}
    display="flex"
    borderBottom="1px solid black"
    height={theme.lineHeights.default * 5 + "rem"}
    backgroundColor="background"
    zIndex={10}
  >
    {days.map(date => (
      <Box
        flexBasis="100%"
        borderLeft="1px solid black"
        textStyle="headingSmall"
        padding="0.5rem"
      >
        {format(date, "EEEE")}
        <br />
        {format(date, "do")}
      </Box>
    ))}
  </Box>
)

const groupShows = (calendarData: CalendarData) => {
  const showsByDay: { day: Date; shows: CalendarShowProps[] }[] = []
  const add = (dayDate: Date, props: CalendarShowProps) => {
    const day = showsByDay.find(item => isEqual(dayDate, item.day))
    if (day) {
      day.shows.push(props)
    } else {
      showsByDay.push({ day: dayDate, shows: [props] })
    }
  }
  Object.values(calendarData.shows).map(({ data }) => {
    Object.values(data).forEach(showData => {
      const start = getDate(showData.start_timestamp)
      const end = getDate(showData.end_timestamp)
      const startDay = startOfDay(start)
      const endDay = startOfDay(end)
      if (!isEqual(startDay, endDay) && !isEqual(end, endDay)) {
        add(startDay, { start, end: endDay, showData })
        add(endDay, { start: endDay, end, showData })
      } else {
        add(startDay, { start, end, showData })
      }
    })
  })
  return showsByDay
}

interface CalendarProps {
  calendarData: CalendarData
}
export const Calendar = memo(({ calendarData }: CalendarProps) => {
  const showsByDay = groupShows(calendarData).slice(0, 7)
  return (
    <Box height="100%" overflowY="auto" borderRight="1px solid black">
      <div>
        <CalendarHeader days={showsByDay.map(data => data.day)} />
        <Box
          display="flex"
          height={DAY_HEIGHT * theme.lineHeights.default + "em"}
        >
          {showsByDay.map(({ day, shows }) => {
            const dayStartTime = day.getTime()
            return (
              <Box height="100%" flexBasis="100%" borderLeft="1px solid black">
                {shows.map(({ start, end, showData }, i) => {
                  const prev = shows[i - 1]
                  const startTime = start.getTime() - dayStartTime
                  const duration = end.getTime() - start.getTime()
                  return (
                    <Box
                      borderTop={
                        !isEqual(start, prev ? prev.end : day)
                          ? "1px solid black"
                          : undefined
                      }
                      borderBottom="1px solid black"
                      position="absolute"
                      width="100%"
                      style={{
                        top:
                          startTime * LINES_PER_MS * theme.lineHeights.default +
                          "em",
                        height:
                          duration * LINES_PER_MS * theme.lineHeights.default +
                          "em",
                      }}
                    >
                      <CalendarShow
                        key={showData.instance_id}
                        start={start}
                        end={end}
                        showData={showData}
                      />
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      </div>
    </Box>
  )
})
